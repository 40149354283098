// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-js": () => import("/opt/build/repo/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-service-js": () => import("/opt/build/repo/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-current-conditions-js": () => import("/opt/build/repo/src/pages/current-conditions.js" /* webpackChunkName: "component---src-pages-current-conditions-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

